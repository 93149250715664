.wrong{
    background-color: red;
}
.right{
    background-color:green;
}
.normal{
    background-color: transparent;
}
.unanswered{
    background-color: grey;
}